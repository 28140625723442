import React from "react";

const { getImageUrl } = require("pages/Utility/functions");

const CONTACT_US = `<tr>
<td>
	<div class="notify_div">
		<img class="mailBox" src="https://hangar-storage.s3.amazonaws.com/icons/mail.png"
			alt="">
		<div class="mailText">
			<div class="question_1">Reservation questions?</div>
			<div class="question_2">Please <a style="color: ##00AEEF !important;" class="contact_link" href="https://www.hangardirect.com/help-desk/get-in-touch" target="_blank"> 
			<span style="color: ##00AEEF !important;">contact us</span>
		 </a> with questions or concerns related to your booking.
			</div>
		</div>
	</div>
</td>
</tr>`;
const FOOTER = `<tr>
<td>
	<div style="background: ##00AEEF;" class="footer">
		<div>
			<a href="https://www.hangardirect.com/" target="_blank">
				<img class="footerLogo"
					src="https://hangar-storage.s3.amazonaws.com/icons/white-logo.png" />
			</a>
		</div>
		<div class="social_icons">
			<a href="https://www.facebook.com/people/Hangar-Direct/100085554487814/" target="_blank">
				<img class="footerico"
					src="https://hangar-storage.s3.amazonaws.com/icons/facebook.png" />
			</a>
			<a href="https://www.linkedin.com/company/hangar-direct/" target="_blank">
				<img class="footerico" src="https://hangar-storage.s3.amazonaws.com/icons/linkedin.png" />
			</a>
			<a href="https://twitter.com/HangarDirect" target="_blank">
				<img class="footerico"
					src="https://hangar-storage.s3.amazonaws.com/icons/twitter.png" />
			</a>
		</div>
	</div>
</td>
</tr>`;
// let amenities = ['wifi', "cameras", "kitchen", "jet fuel", "restroom"]
const STYLE = `<style>
	  
body {
  margin: 0;
  padding: 0;
  color: #103947;
  text-align: center;
  font-family: 'Noto Sans', Arial, sans-serif;
}

.content {
  display: block;
  background-color: #f7f7f7 !important;
}

.main_table {
  border-collapse: collapse;
  width: 600px;
  margin: 0 auto;
  background-color: #ffffff;
  border-spacing: 0;
  border-color: none;
}

.header {
  text-align: center;
}

.header .header_content {
  width: 100%;
  padding: 30px 50px;
}

.logo {
  height: 45px !important;
  margin-left: 19% !important;
}

.title_slug {
  color: #333;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  padding: 30px 30px 50px 30px;
}

hr {
  border: 0.5px solid #EBEBEB;
  margin: 0;
  background-color: #EBEBEB;
}

.user_info {
  padding: 50px;
}

.user_name {
  color: #495057;
  font-size: 18px;
  font-style: normal;
  padding-bottom: 5px;
  font-weight: 600;
  line-height: 150%;
  text-align: left;
}

.user_description {
  color: #495057;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  line-height: 150%;
}

.airplane {
  padding: 50px;
  text-align: left;
}

.plane_div {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  gap: 5px
}

.plane {
  height: 16px;
}

.text_plane {
  color: #495057;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  margin-left:5px;
}
.text_plane2 {
  color: #495057;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
}

.order_div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.order_id {
  color: #495057;
  text-align: right;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
}

.status {
  color: #E58037;
  text-align: right;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  margin-left:auto
}

.hangar_picture {
  height: 55px;
  border-radius: 7px;
}

.hangar_title {
  color: #495057;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  margin-left:5px; 
  padding-top:15px;
}

.hangar_div {
  display: flex;
  justify-content: space-between;
  gap: 5px;
}

.picture_title {
  display: flex;
  gap: 5px;
}

.days_count {
  color: #495057;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  margin-left:auto;
  padding-top:15px
}

.mainDivDetail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.head-detail {
  color: #495057;
  text-align: right;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  /* 18px */
}

.detail {
  color: #000;
  text-align: right;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  margin-left:auto
  /* 18px */
}

.head-detail2 {
  color: #495057;
  text-align: right;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  /* 19.2px */
}

.detail2 {
  color: #000;
  text-align: right;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  margin-left:auto
  /* 19.2px */
}
.notify_div {
  margin: 50px;
  display: flex;
  padding: 15px 15px 15px 30px;
  /* justify-content: center; */
  align-items: center;
  text-align: left;
  gap: 30px;
  align-self: stretch;
  border-radius: 10px;
  background: #EEFAFF;
}

.question_1 {
  color: #495057;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}

.question_2 {
  color: #495057;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.view_button {
display: flex;
padding: 14px 30px;
justify-content: center;
align-items: center;
gap: 8px;
color: #FFF;
text-align: center;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin:auto;
border:1px solid white;
border-radius: 7px;
background: #00AEEF;
}

.footer {
  display: flex;
  padding: 17px 50px;
  justify-content: space-between;
  align-items: center;
  gap: 25px;
  background-color: #00AEEF;
}
.footerLogo{
  height: 30px;
  width: 203px;
  text-align: left;
}
.social_icons{
  display: flex;
  align-items: center;
  gap: 5px;
  margin-left:auto;
  margin-top:3px;
}
.footerico{
  height:24px;
  width:24px;
  margin-left:16px
}
.mailBox{
  height:66px;
  width:65px
}
.mailText{
  padding-left:30px
}
.contact_link{
color: #00AEEF !important;
font-size: 16px;
text-decoration: none!important;
font-style: normal;
font-weight: 500;
line-height: 150%;
}

@media only screen and (max-width: 320px) {
  body {
	  font-family: 'Roboto', sans-serif;
  }

  .header .header_content td {
	  display: block;
	  padding: 0;
  }

  .logo {
	  padding-bottom: 5px;
  }

  .main_table {
	  width: 100% !important;
  }
}
.svgContainer svg {
display: inline; 
width: 15px; 
padding-right: 5px;
}
</style>`;
export function hangarApproveEmail(hangarData, profileData, amenities, images) {
	const ul_start = `<ul style="display: flex; justify-content: space-between;">`
	const li_start = `<li style="min-width: 50%; padding: 5px 0px; color: #495057; font-weight : 600">`

	const renderAmenities = () => {
		const res = amenities.reduce((acc, item, ind,) => {

			acc.elems.push(item)
			if (acc.elems.length === 2) {
				acc.currentStr += ul_start
				acc.elems.map(amen => {
					acc.currentStr += li_start + amen.icon + amen.name + "</li>"
				})
				acc.currentStr += "</ul>";
				acc.elems = []
			}
			else if (ind === amenities.length - 1) {
				acc.currentStr += ul_start + li_start + item.icon + item.name + "</li></ul>"
			}
			return acc

		}, { currentStr: "", elems: [] })
		return res.currentStr
	}


	return `<html>
		  <head>
		  <link href='https://fonts.googleapis.com/css?family=Noto+Sans' rel='stylesheet' type='text/css'>
		  ${STYLE}
		  </head>
		  
  <body>
  <div class="content">
		  <table class="main_table" cellpadding="0" cellspacing="0">
			  <tbody>
				  <tr class="header">
					  <td>
						  <table class="header_content" cellpadding="0" cellspacing="0">
							  <tbody>
								  <tr>
									  <td style="text-align:left !important">
										  <a href="https://www.hangardirect.com/" target="_blank">
											  <img class="logo"
												  src="https://hangar-storage.s3.amazonaws.com/icons/logo.png" />
										  </a>
									  </td>
  
							  </tbody>
						  </table>
					  </td>
				  </tr>
  
  
  
				  </tr>
				  <tr>
					  <td class="title_slug" >
					  Hurray! Your hangar listing has been successfully added.
					  </td>
				  </tr>
				  <tr>
					  <td>
						  <hr>
					  </td>
				  </tr>
				  <tr>
					  <td class="user_info">
						  <div class="user_name" >Hi ${profileData?.first_name || ""},</div>
						  <div class="user_description" >We're excited to inform you that your recent hangar listing has been successfully listed to the platform. Thank you for choosing Hangar Direct to connect with your future customers!
						  <br />
						  Best regards,
						  <br />
						  The Hangar Direct Team</div>
					  </td>
				  </tr>
				  <tr>
					  <td>
						  <hr>
					  </td>
				  </tr>
                  <tr>
            
                  <td>
                  <div style="width: -webkit-fill-available; display: flex; justify-content:center;  justify-content: space-between; overflow:hidden ;">
                      <div style="padding: 50px 0px 0px 50px;">
                       <img style="border-radius: 10px 0px 0px 10px; height: 235px; width: 235px; object-fit: cover;" src="${getImageUrl(images[0])}" alt="" />
                      </div>



                      <div style=" padding: 50px 50px 20px 5px; display:flex;">
                          <div style=" width: -webkit-fill-available; ">
                              <img style="width: 100%;height: 112px;  object-fit: cover;" src="${getImageUrl(images[1])}" alt="" />
                            
                              <img style="width: 100%; height: 115px; margin-top: 3px; object-fit: cover;" src="${getImageUrl(images[2])}" alt="" />
                            
                          </div>
                          <div style="padding-left : 5px; width: -webkit-fill-available;">
                              <img style="width: 100%;  height: 112px; border-radius: 0px 15px 0px 0px; object-fit: cover;" src="${getImageUrl(images[3])}" alt="" />
                              <img style="width: 100%; margin-top: 3px; height: 115px;  border-radius: 0px 0px 15px 0px; object-fit: cover;" src="${getImageUrl(images[4])}" alt="" />
                          </div>
                      </div>
                  </div>
              </td>
                  </tr>

                  <tr >
                    <td style="padding: 0px 50px; font-weight: 800; color:#495057; font-size : 20px;">
                       ${hangarData?.title || ""}
                    </td>
                </tr>
                <tr >
                    <td style="padding: 15px 50px; font-size: 14px; font-weight: 600; color:#495057; text-decoration: underline;">
                        ${hangarData?.address || ""}
                    </td>
                </tr>
                <tr >
                    <td style="padding: 0px 50px 10px 50px; font-size: 14px; display: flex; align-items: center; font-weight: 600; color:#495057; align-items: center;">
                        <span style="padding: 0px 15px 0px 0px;">Listed By</span>
                        <span><img style="width: 30px; height: 30px; border-radius: 100%;" src="${profileData?.img_url || ""}" alt=""></span>
                        <span style="padding: 0px 15px;">${profileData?.first_name + profileData?.last_name}</span>
                    </td>
                </tr>

                <tr>
                    <td>
                        <hr>
                    </td>
                </tr>
                <tr >
                    <td style="padding: 0px 50px 10px 50px;">
                    <div style="text-align: center;display=${amenities?.length ? "block":"hidden"} font-size: 20px; font-weight: 800; color: #495057; padding: 20px 0px 0px 0px">
                        This Hangar Offers
                    </div>
					<div class="svgContainer">
						${renderAmenities()}
					</div>
                    </td>
                </tr>
				<tr>
					<td  style="padding: 10px 50px 20px 50px; color : #495057; font-weight : 600;">
						<p>Your hangar is now visible to our community of aviation enthusiasts and professionals actively seeking hangar spaces. This visibility significantly increases the chances of finding suitable tenants.</p>
					</td>
				</tr>
		            <tr style="text-align: center;" >
					  <td>
					  	<a  style="text-decoration:none;margin-top:10px" href="https://www.hangardirect.com/${hangarData?.title?.replace(" ", "-")}/hangar/${hangarData?.id}" >
						  <button style="background: #00AEEF; color: #ffffff;" class="view_button">View Request</button>
						</a>
					  </td>
				  </tr>
				  ${CONTACT_US}
				  ${FOOTER}
				  <tr class="spacing_20">
					  <td></td>
				  </tr>
  
			  </tbody>
		  </table>
	  </div>

  </body>
	  </html>
	  `;
}
export function matchingHangarAlert(hangarData, profileData, images,alertData) {
	return `<html>
		  <head>
		  <link href='https://fonts.googleapis.com/css?family=Noto+Sans' rel='stylesheet' type='text/css'>
		  ${STYLE}
		  </head>
		  
  <body>
  <div class="content">
		   <table class="main_table" cellpadding="0" cellspacing="0">
                <tbody>
                    <tr class="header">
                        <td>
                            <table class="header_content" cellpadding="0" cellspacing="0">
                                <tbody>
                                    <tr>
                                        <td style="text-align:left !important">
                                            <a href="https://www.hangardirect.com/" target="_blank">
                                                <img class="logo"
                                                    src="https://hangar-storage.s3.amazonaws.com/icons/logo.png" />
                                            </a>
                                        </td>
    
                                </tbody>
                            </table>
                        </td>
                    </tr>
    
    
    
                    </tr>
                    <tr>
                        <td class="title_slug" >
                            A Hangar Matching Your Preferences is Now Available!
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <hr>
                        </td>
                    </tr>
                    <tr>
                        <td class="user_info">
                            <div class="user_name" >Hi ${profileData?.first_name || ""},</div>
                            <div class="user_description" >Great news! A hangar that matches your alert preferences has just become available on Hangar Direct. We're excited to share this opportunity with you.</div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <hr>
                        </td>
                    </tr>
                    <tr>
              
                    <td>
                    <div style="width: -webkit-fill-available; display: flex; justify-content:center;  justify-content: space-between; overflow:hidden ;">
                        <div style="padding: 50px 50px 0px 50px; width: 100%;">
                         <img style="border-radius: 10px 10px 10px 10px; height: 235px; width: 100%; object-fit: cover;" src="${getImageUrl(images[0])}" alt="" />
                        </div>
                    </div>
                </td>
                    </tr>
  
                    <tr >
                      <td style="padding: 0px 50px; font-weight: 800; color:#495057; font-size : 20px;">
                         ${hangarData?.title || ""}
                      </td>
                  </tr>
                  <tr >
                      <td style="padding: 0px 50px 15px 50px;marginBottom:15px; font-size: 14px; font-weight: 600; color:#495057; text-decoration: underline;">
                          ${hangarData?.address || ""}
                      </td>
                  </tr>
                 <tr style="display:${alertData?.distance?.length ? "block":"hidden"};" >
                      <td style="padding: 0px 50px;font-size: 14px; color:#495057; text-transform: capitalize;">
                        Distance: Within ${alertData?.distance ?? ""} miles
                      </td>
                  </tr>
                  <tr style="display:${alertData?.term?.length ? "block":"hidden"};" >
                      <td style="padding: 0px 50px 15px 50px;font-size: 14px; color:#495057; text-transform: capitalize;">
                      Term: ${alertData?.term === "all" ? "Nightly, Monthly and For Sale" : alertData?.term === "sale"? "For sale" :alertData?.term }
                      </td>
                  </tr>
                  <tr style="display: flex; justify-content: center;">
                      <td style="padding: 0px 50px 10px 50px; font-size: 1px; display: flex; justify-content: center; font-weight: 600; color:#495057; align-items: center;width:100%;">
					  <p style="text-align:center;width:100%;">
					  <a  style="text-decoration:none;margin-top:10px" href="https://www.hangardirect.com/${hangarData?.title?.replace(" ", "-")}/hangar/${hangarData?.id}" >
					  <button style="background: #00AEEF; font-size: 14px; color: #ffffff;" class="view_button">View Hangar Listing</button>
					  </a>
					  </p>
                      </td>
                  </tr>
  
                 
                  <tr>
                      <td  style="padding: 10px 50px 20px 50px; color : #495057;text-align: start;">
                          <p>We understand that your needs might change, and we want to make sure your alerts are always relevant. If you'd like to update your alert preferences, you can do so easily through your profile.</p>
                          <p style="text-align: center;">
                         <a target="_blank" href="https://www.hangardirect.com/account/user/profile">Update Alert Preferences</a>
                        </p>
                        <p>Thank you for using Hangar Direct! We're here to help you find the perfect hangar space.
                            <br><br>Best regards,
                        <br><span>The Hangar Direct Support Team</span></p>
                      </td>
                  </tr>
				  ${CONTACT_US}
				  ${FOOTER}
				  <tr class="spacing_20">
					  <td></td>
				  </tr>
  
			  </tbody>
		  </table>
	  </div>

  </body>
	  </html>
	  `;
}

