import { Spinner } from 'common/loading';
import FieldError from 'components/Common/FieldError';
import { TABLES } from 'pages/Utility/constants';
import { supabase } from 'pages/Utility/supabase';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import AsyncSelect from 'react-select/async';
import { toast } from 'react-toastify';
import { Button, Container } from 'reactstrap';


const CustomInput = ({ label, value, name, placeholder, onChange, steric, error }) => {
    return (
        <div className="mb-">
            <label
                htmlFor="titleinput"
                className="font-weight-bold mb-0 form-label fs-5"
            >
                {label} <span className="text-info">{steric}</span>
            </label>
            <input
                style={{
                    height: "48px",
                    borderRadius: "8px",
                    backgroundColor: "#F8F8F8"
                }}
                type="text"
                value={value}
                className="form-control"
                name={name}
                placeholder={placeholder}
                onChange={onChange}
            />
            <FieldError text={error} />

        </div>
    );
};


const DEFAULT_FIELDS = {
    title: "",
    widget_id: "",
    slug: "",
    hangars: [],
}
const WidgetCollectionAdd = () => {

    document.title = "Widget Collection | HangarDirect"

    const history = useHistory()
    const [loading, setLoading] = useState(false)
    const [fields, setFields] = useState(DEFAULT_FIELDS);
    const [widgets, setWidgets] = useState([]);
    const [pastData, setPastData] = useState(null);
    const id = history?.location?.search?.replace("?id=", "")
    const [errors, setErrors] = useState({
        title: "",
        widget_id: "",
        slug: "",
        hangars: "",
    })
    useEffect(() => {
        getWidgetsData()
        if (id)
            fetchRow()
    }, [])

    const fetchRow = async () => {
        try {
            const { data, error } = await supabase
                .from(TABLES.WIDGET_COLLECTION)
                .select(`*,${TABLES.COLLECTION_PRODUCT}(${TABLES.HANGARS}(id,title))`)
                .eq("id", id)
                .single()

            if (error) throw error
            const pastData = {
                ...data,
                hangars: data?.collection_product.map(i => ({ value: i.hangars.id, label: i.hangars.title }))

            }
            setFields(pastData);
            setPastData(pastData);



        } catch (error) {
            console.log(error.message)

            // history.goBack()
        }
    }

    const getWidgetsData = async () => {

        try {

            const { data, error } = await supabase
                .from(TABLES.WIDGET,)
                .select("id, name")

            if (error) {
                throw error
            }
            else
                setWidgets(data)
        } catch (error) {
            toast.error(error.message);
        }

    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target

        setFields({ ...fields, [name]: value })
    }

    const validation = () => {
        let err: any = {}

        if (!fields?.title) err.title = "Please enter airplane title"
        if (!fields?.slug) err.slug = "Please enter slug"
        if (!fields?.widget_id) err.widget_id = "Please Select Widget"
        if (!fields?.hangars.length) err.hangars = "Please add at least 1 hangar"

        if (Object.keys(err).length) {
            setErrors(err)
            return false
        }
        return true
    }

    const searchHangars = async (txt: string) => {

        try {

            if (txt.length < 3) return

            const { data, error } = await supabase.from(TABLES.HANGARS,).select("id, title").ilike("title", `%${txt}%`)

            if (error) {
                throw error
            }
            else
                return data.map(h => ({ value: h.id, label: h.title }))
        } catch (error) {
            toast.error(error.message);
        }

    }

    function didUserChanged() {
        let changed = false
        if (fields?.title !== pastData?.title)
            changed = true
        if (fields?.slug !== pastData?.slug)
            changed = true
        if (fields?.widget_id !== pastData?.widget_id)
            changed = true
        if (fields?.hangars.toString() !== pastData?.hangars.toString())
            changed = true
        if (!changed) throw new Error("Nothing to update")

    }

    const handleSubmit = async e => {
        e.preventDefault()

        try {

            if (!validation()) return;
            else if (!!id)
                didUserChanged()

            setLoading(true)
            const { title, widget_id, slug, hangars } = fields

            const body = {
                title, widget_id, slug
            }


            const { error, data: collectionRes } = await supabase.from(TABLES.WIDGET_COLLECTION).upsert({ ...body, id: id || undefined }).select()

            if (error) throw error

            const collection_id = id || collectionRes[0].id
            const products = hangars.map(h => ({ collection_id, hangar_id: h.value }))
            id && await supabase.from(TABLES.COLLECTION_PRODUCT).delete().eq("collection_id", id);
            const { error: err, } = await supabase.from(TABLES.COLLECTION_PRODUCT).insert(products);
            if (err) throw err

            setFields(DEFAULT_FIELDS)
            toast.success(id ? "Update successfully" : "Added successfully")
            setLoading(false)
            history.goBack()


        } catch (error) {
            toast.error(error?.message)
            console.log('error: ', error.message);
        }
    }

    return (

        <div className="page-content">
            <Container fluid>
                <div className="mx- pb-4 shadow-md">
                    <div className="d-flex justify-content-between ">
                        <h4 className="mx-9 mb-3 text-[#252F40]  text-lg font-semibold">
                            {id ? "Update Collection" : "Add New Collection"}
                        </h4>
                    </div>
                    <div style={{ background: "white" }} className="p-4 rounded ">

                        <div className="row mt-4">
                            <div className="col-lg-6 ">

                                <CustomInput label={"Title"} steric={"*"} name="title" error={errors.title}
                                    value={fields.title} onChange={handleChange} placeholder={"Enter Collection Title"} />

                            </div>
                            <div className="col-lg-6">
                                <CustomInput label={"Slug"} placeholder={"Slug"} name={"slug"} value={fields?.slug} error={errors.slug} steric onChange={handleChange} />

                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-lg-6 ">


                                <div className="d-flex flex-column">
                                    <label className='font-weight-bold mb-0 form-label fs-5' htmlFor="mySelect">Widget <span className="text-info">*</span></label>

                                    <select style={{
                                        width: "100%", padding: "13px",
                                        borderRadius: "8px",
                                        border: "1px solid #D7D7D7",
                                        backgroundColor: "#F8F8F8"
                                    }}
                                        onChange={handleChange} value={fields.widget_id} name={'widget_id'} id="mySelect">

                                        <option value={""}>Select</option>
                                        {
                                            widgets.map(i => ({ label: i.name, value: i.id })).map(i =>
                                                <option key={i.value} value={i.value}>{i.label}</option>

                                            )
                                        }
                                    </select>
                                    <FieldError text={errors.widget_id} />
                                </div>


                            </div>

                        </div>

                        <div className="col-lg-12 mt-4">

                            <label className='font-weight-bold mb-0 form-label fs-5' htmlFor="mySelect">{"Select Hangars (min 3 characters required to search)"} <span className="text-info">*</span></label>

                            <AsyncSelect
                                isMulti
                                cacheOptions
                                hideSelectedOptions
                                value={fields.hangars}
                                onChange={values => handleChange({ target: { name: "hangars", value: values } })}
                                placeholder="Search for hangars (min 3 characters required to search)"
                                loadOptions={searchHangars}
                            />
                            <FieldError text={errors.hangars} />

                        </div>


                        <div className="d-flex justify-content-end my-2 gap-2 mt-5">
                            <Button
                                type="button"

                                className="bg-secondary btn-rounded-3 px-4 py-2"
                                onClick={() => history.goBack()}

                            >
                                {"Cancel"}
                            </Button>
                            <Button
                                type="button"
                                color="info"
                                className="btn-md btn-rounded-3 px-4 py-2"
                                onClick={handleSubmit}

                            >
                                {loading ? <Spinner /> : !id ? "Add" : "Update"}
                            </Button>
                        </div>

                    </div>

                </div>
            </Container>
        </div>
    )
}

export default WidgetCollectionAdd

