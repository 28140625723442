import React from "react"
import { Button, Col, Container, Row } from "reactstrap"
import { useEffect, useState, useMemo } from "react"
import { supabase } from "pages/Utility/supabase"
import DefaultLoading from "common/loading"
import { useHistory } from "react-router-dom/cjs/react-router-dom"
import { TABLES } from "pages/Utility/constants"
import Swal from "sweetalert2"
import PromosLists from "./PromoLists"


const PromoPackages = () => {
    const history = useHistory()
    const [loading, setLoading] = useState(false)
    const [promoPackages, setPromosPackages] = useState([])
    const [currentItems, setCurrentItems] = useState([]);

    document.title = "Promo Packages | HangarDirect"


    useEffect(() => {
        fetchPromoPackages()
    }, [])

    const columns = useMemo(
        () => [

            {
                Header: "#",
                filterable: false,
                disableFilters: true,
                Cell: cellProps => {
                    return cellProps?.row?.index + 1
                },
            },
            {
                Header: "Tier",
                accessor: "tier",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Amount",
                accessor: "amount",
                disableFilters: true,
                filterable: false,
                Cell: cellProps => {
                    const amount = cellProps?.value;
                    return `$${amount}`
                },
            },
            {
                Header: "Promotion Days",
                accessor: "promo_days",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Media Advertising",
                accessor: "media_advertising",
                disableFilters: true,
                filterable: false,
                Cell: cellProps => {
                    const value = cellProps?.value;
                    return `${value}`
                },
            },
            {
                Header: "Marketing Team",
                accessor: "marketing_team",
                disableFilters: true,
                filterable: false,
                Cell: cellProps => {
                    const value = cellProps?.value;
                    return `${value}`
                },
            },
            {
                Header: "Increase Exposure",
                accessor: "exposure",
                disableFilters: true,
                filterable: false,
                Cell: cellProps => {
                    const value = cellProps?.value;
                    return `Up to ${value}x`
                },
            },

            {
                Header: "Features",
                accessor: "features",
                disableFilters: true,
                filterable: false,
                Cell: cellProps => {
                    const features = cellProps?.value;
                    return <p>{features?.join(" - ")}</p>
                },
            },
            {
                Header: "Action",
                disableFilters: true,
                filterable: false,
                accessor: row => {
                    return <div className="d-flex gap-1 cursor-pointer">
                        <img onClick={() => history.push("promo/add-new?id=" + row.id)} style={{ height: "30px" }} src="/images/edit.png" alt="" />
                        {/* <img onClick={() => deletePopup(row.id)} style={{ height: "30px" }} src="/images/delete.png" alt="" /> */}
                    </div>
                },
            },

        ],
        []
    )

    // const deletePopup = async (id) => {

    //     Swal.fire({
    //         title: "Do you want to delete this aircraft?",
    //         showDenyButton: true,
    //         showCancelButton: true,
    //         showConfirmButton: false,
    //         denyButtonText: `Delete`
    //     }).then((result) => {
    //         /* Read more about isConfirmed, isDenied below */
    //         if (result.isDenied) {
    //             deleteRow(id)
    //         }
    //         Swal.close()
    //     });
    // }

    // const deleteRow = async (id) => {

    //     const { data, error } = await supabase.from(TABLES.PLANE_LIST).delete().eq("id", id)
    //     if (error) console.log(error.message)
    //     else {
    //         // setPlaneList(planeList.filter(i => i.id !== id))
    //         getPlanesList()
    //     }
    // }

    const fetchPromoPackages = async () => {
        try {

            setLoading(true)
            const { data: promos, error } = await supabase
                .from(TABLES.PROMO_PACKAGES)
                .select('*')
                .order("id", { ascending: true })

            if (error) {
                throw error
            }
            setPromosPackages(promos)
            setCurrentItems(promos);
            setLoading(false)
        } catch (error) {
            setLoading(false)
        } finally {
            setLoading(false)
        }
    }

    return (

        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {loading ? <DefaultLoading /> :
                        <Row>
                            <Col lg="12">
                                <PromosLists
                                    columns={columns}
                                    data={[promoPackages]}
                                    currentItems={currentItems}
                                    setCurrentItems={setCurrentItems}
                                    header={<div className="d-flex justify-content-end">
                                        <Button
                                            type="button"
                                            color="info"
                                            className=" rounded"
                                            onClick={() => {
                                                // handleModelData(cellProps.row.original)
                                                history.push("/promo/add-new")
                                            }}
                                        >
                                            + Add New Promo
                                        </Button>
                                    </div>}
                                    className="!mb-0"
                                />
                            </Col>
                        </Row>
                    }
                </Container>
            </div>
        </React.Fragment>
    )
}

export default PromoPackages
